// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-apply-js": () => import("./../../../src/pages/apply.js" /* webpackChunkName: "component---src-pages-apply-js" */),
  "component---src-pages-finalists-js": () => import("./../../../src/pages/finalists.js" /* webpackChunkName: "component---src-pages-finalists-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-judges-js": () => import("./../../../src/pages/judges.js" /* webpackChunkName: "component---src-pages-judges-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-pages-winners-js": () => import("./../../../src/pages/winners.js" /* webpackChunkName: "component---src-pages-winners-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-finalist-category-js": () => import("./../../../src/templates/finalist-category.js" /* webpackChunkName: "component---src-templates-finalist-category-js" */),
  "component---src-templates-form-js": () => import("./../../../src/templates/form.js" /* webpackChunkName: "component---src-templates-form-js" */)
}

